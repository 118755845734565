
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function hierarchicalContainerRT () {
    function repeatHierarchicalGroups1(hierarchicalGroups, hierarchicalGroupsIndex) {
        return [hierarchicalGroups(function () {
                return _createElement('div', { 'className': 'cm_vehicle-categories__topline-wrapper' }, _createElement('a', {
                    'href': !this.subvalues.length ? `${ this.href }` : `#nav_${ this.term }`,
                    'className': 'cm_vehicle-categories__topline-link',
                    'title': this.value
                }, _createElement('img', {
                    'alt': this.value,
                    'className': 'cm_vehicle-categories__topline-image cm_vehicle-categories_category-image',
                    'src': this.getImageOrDefault(this.imageUrl)
                }), _createElement('div', { 'className': 'cm_vehicle-categories__topline-title' }, this.value)));
            }, { count: undefined })];
    }
    function repeatHierarchicalGroups2(hierarchicalGroups, hierarchicalGroupsIndex) {
        return [hierarchicalGroups(function () {
                function repeatSubvalues1(subvalues, subvaluesIndex) {
                    return [subvalues(function () {
                            return _createElement('div', { 'className': 'cm_vehicle-categories_category-container' }, _createElement('a', {
                                'href': `${ window.Shopify.routes.root.slice(0, -1) }${ this.href }`,
                                'className': 'cm_vehicle-categories_link'
                            }, _createElement('div', { 'className': 'cm_vehicle-categories_category-image-container' }, _createElement('img', {
                                'alt': this.value,
                                'className': 'cm_vehicle-categories_category-image',
                                'src': this.getImageOrDefault(this.imageUrl)
                            })), _createElement('div', { 'className': 'cm_vehicle-categories_category-title-container' }, _createElement('h2', { 'className': 'cm_vehicle-categories_category-title' }, this.value), _createElement('svg', {
                                'className': 'icon chevron',
                                'title': 'icon chevron right'
                            }, _createElement('path', { 'd': 'M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z' })))));
                        }, { count: undefined })];
                }
                return _createElement('div', { 'className': 'cm_vehicle-categories_category-wrapper' }, !this.subvalues.length && !this.isMultiLevel ? [_createElement('div', {
                        'className': 'cm_vehicle-categories_category-container',
                        'key': '560'
                    }, _createElement('a', {
                        'href': `${ window.Shopify.routes.root.slice(0, -1) }${ this.href }`,
                        'className': 'cm_vehicle-categories_link'
                    }, _createElement('div', { 'className': 'cm_vehicle-categories_category-image-container' }, _createElement('img', {
                        'alt': this.value,
                        'className': 'cm_vehicle-categories_category-image',
                        'src': this.getImageOrDefault(this.imageUrl)
                    })), _createElement('div', { 'className': 'cm_vehicle-categories_category-title-container' }, _createElement('h2', { 'className': 'cm_vehicle-categories_category-title' }, this.value), _createElement('svg', {
                        'className': 'icon chevron',
                        'title': 'icon chevron right'
                    }, _createElement('path', { 'd': 'M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z' })))))] : null, this.subvalues.length ? [
                    _createElement('h1', {
                        'className': 'cm_vehicle-categories_category-wrapper-title',
                        'id': 'nav_' + this.term,
                        'key': '8191'
                    }, _createElement('a', {
                        'className': 'cm_vehicle-categories_category-wrapper-title-link cm_vehicle-categories_link',
                        'href': this.href
                    }, '\n        ', this.value, '\n      ')),
                    [this.vehicleWidget(function () {
                            return _createElement('div', { 'className': 'cm_vehicle-widget__vehicle-category-wrapper cm_vehicleWidget' }, this.template === 'locked' ? _createElement('div', {
                                'className': 'cmTemplate_locked',
                                'key': '81'
                            }, this.vehicleString) : null);
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })],
                    _createElement.apply(this, [
                        'div',
                        {
                            'className': 'cm_vehicle-categories_category-repeater  cmRepeater_subvalues',
                            'key': '8195'
                        },
                        _map(this.subvalues, repeatSubvalues1.bind(this))
                    ])
                ] : null);
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_vehicle-categories' + (!this.isMultiLevel ? ' cm_vehicle-categories__flat ' : ' ') }, [this.vehicleWidget(function () {
            function repeatSelects1(selects, selectsIndex) {
                return [selects(function () {
                        function repeatEntry1(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n        ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n      ');
                        }
                        return _createElement('div', { 'className': this.extraClassName }, _createElement('div', { 'className': 'cm_select_toggle cm_select_toggle__native cm_toggle-dropdown' }, this.index || this.index === 0 ? _createElement('span', {
                            'className': 'index',
                            'key': '118'
                        }, this.index + 1) : null, _createElement.apply(this, [
                            'select',
                            {
                                'className': 'cm_select_inner-select cm_select__pretty',
                                'onChange': this.onChange,
                                'value': this.firstSelectedTerm ? this.firstSelectedTerm : '',
                                'aria-selected': this.selectedEntries.length ? true : false,
                                'disabled': this.disabled
                            },
                            !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                'value': '',
                                'key': 'null-option'
                            }, '\n        ', this.title, '\n      ') : null,
                            this.loading ? _createElement('option', {
                                'key': 'loading-option',
                                'disabled': true
                            }, '...loading...') : null,
                            _map(this.entries, repeatEntry1.bind(this))
                        ])));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__vehicle-categories  cm_vehicleWidget' }, this.template === 'active' ? _createElement('div', {
                'className': 'cm_vehicle-widget_dropdowns cmTemplate_active',
                'key': '87'
            }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, window.Convermax.config?.selectYourVehicleTitle || 'Select Your Vehicle'), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
                'div',
                { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
                _map(this.selects, repeatSelects1.bind(this))
            ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go btn btn--primary',
                'onClick': this.selectVehicle,
                'disabled': !this.allowToSetVehicle,
                'role': 'button',
                'tabIndex': this.allowToSetVehicle ? '0' : '-1'
            }, '\n    GO\n  '), _createElement('div', {
                'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear btn btn--secondary',
                'onClick': this.discardVehicle,
                'disabled': !this.allowToDiscardVehicle,
                'role': 'button',
                'tabIndex': this.allowToDiscardVehicle ? '0' : '-1'
            }, [_createElement('svg', {
                    'xmlns': 'http://www.w3.org/2000/svg',
                    'className': 'cm_icon cm_icon-reset',
                    'height': '16px',
                    'viewBox': '0 0 16 16',
                    'key': '26840'
                }, _createElement('path', { 'd': 'M2.083,9H0.062H0v5l1.481-1.361C2.932,14.673,5.311,16,8,16c4.08,0,7.446-3.054,7.938-7h-2.021 c-0.476,2.838-2.944,5-5.917,5c-2.106,0-3.96-1.086-5.03-2.729L5.441,9H2.083z' }), _createElement('path', { 'd': 'M8,0C3.92,0,0.554,3.054,0.062,7h2.021C2.559,4.162,5.027,2,8,2c2.169,0,4.07,1.151,5.124,2.876 L11,7h2h0.917h2.021H16V2l-1.432,1.432C13.123,1.357,10.72,0,8,0z' }))])))) : null, this.template === 'locked' ? _createElement('div', {
                'className': 'cm_vehicle-widget_label cmTemplate_locked',
                'key': '3205'
            }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, '\n  ', this.vehicleString, '\n  ', _createElement('span', { 'className': 'cm_vehicle-widget_subtitle' }, this.fitmentSearchTitle), [this.facetToggle(function () {
                    return _createElement('div', { 'className': 'cm_facetToggle' }, _createElement('span', {
                        'className': 'cm_facet-toggle_label',
                        'onClick': this.toggleFacet
                    }, '\n  Show Universal Parts\n'), _createElement('label', { 'className': 'cm_facet-toggle_switch' }, _createElement('input', {
                        'className': 'cm_facet-toggle_input',
                        'type': 'checkbox',
                        'checked': this.isToggled,
                        'onChange': this.toggleFacet
                    }), _createElement('span', { 'className': 'cm_facet-toggle_slider' })));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                'className': 'cm_vehicle-widget_button cm_vehicle-widget__change btn btn--primary',
                'onClick': this.changeVehicle,
                'role': 'button',
                'tabIndex': '0'
            }, '\n      Change\n    '), _createElement('div', {
                'className': 'cm_vehicle-widget_button cm_vehicle-widget__discard btn btn--secondary',
                'onClick': this.discardVehicle,
                'role': 'button',
                'tabIndex': '0'
            }, '\n      Clear\n    ')))) : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], this.isMultiLevel ? _createElement.apply(this, [
        'div',
        {
            'className': 'cm_vehicle-categories_category-wrapper-repeater cm_vehicle-categories__topline  cmRepeater_hierarchicalGroups',
            'key': '6443'
        },
        _map(this.hierarchicalGroups, repeatHierarchicalGroups1.bind(this))
    ]) : null, _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-categories_category-wrapper-repeater  cmRepeater_hierarchicalGroups' },
        _map(this.hierarchicalGroups, repeatHierarchicalGroups2.bind(this))
    ]));
}
        export const componentNames = ["cm:facetToggle","cm:vehicleWidget","cm:vehicleWidget"]